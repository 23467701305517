import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private versionSubject = new BehaviorSubject<string | null>(null);
  private newVersionSubject = new BehaviorSubject<string | null>(null);
  
  version$ = this.versionSubject.asObservable();
  newVersion$ = this.newVersionSubject.asObservable();

  constructor() {
    // Initialize with current stored version
    const currentVersion = localStorage.getItem('appVersion') || 'v1.0.0';
    this.versionSubject.next(currentVersion);
  }

  // Call this when a new version is detected from the server
  setNewVersion(version: string) {
    console.log('New version detected:', version);
    this.newVersionSubject.next(version);
  }

  // User confirms update - actually update the version
  updateToNewVersion() {
    const newVersion = this.newVersionSubject.value;
    if (newVersion) {
      console.log('Updating to new version:', newVersion);
      localStorage.setItem('appVersion', newVersion);
      this.versionSubject.next(newVersion);
      this.newVersionSubject.next(null); // Reset new version
    }
  }

  setDefaultVersion(version){
    // const newVersion = this.newVersionSubject.value;
    localStorage.setItem('appVersion', version);
  }
}