import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { VersionService } from '../services/version.service';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {

  constructor(private versionService: VersionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const serverVersion = event.headers.get('X-App-Version');
          const currentVersion = localStorage.getItem('appVersion');
        //   console.log('Version from server:', serverVersion);
        //   console.log('Current app version:', currentVersion);
          if(!currentVersion){
            this.versionService.setDefaultVersion(serverVersion)
          }
          if (serverVersion && currentVersion && serverVersion !== currentVersion) {
            // Notify VersionService about the new version
            this.versionService.setNewVersion(serverVersion);
          }
        }
      })
    );
  }
}